  //COLORS
  $red:#da0a0a;
  $green: #33d5ba;
  $light-green:#f2fffd;
  $lighter-green:#d3efea;
  $black:#303030;
  $grey:#7a869a;
  $form-bg:#f6f6f6;
  $overlay:rgba(0, 0, 0, 0.16);
  //OTHERS
  $breakpoints: ( "phone-small": 320px, "phone": 400px, "phone-wide": 480px, "phablet": 560px, "tablet-small": 640px, "tablet": 768px, "fluid-out":960px, "tablet-wide": 1024px, "desktop": 1248px, "desktop-med": 1350px, "desktop-wide": 1440px, "excess": 2000px);
  $menu-shadow: 0 0 10px rgba(0, 0, 0, 0.06),
  0 4px 4px rgba(0, 0, 0, 0.12);
  $header-shadow:0px 2px 6px #d1d6de4d;
  @mixin flex-center($direction: row) {
    display: flex;
    flex-flow: $direction;
    justify-content: center;
    align-items: center;
  }
  
  @mixin flex-btw($direction: row, $wrap: nowrap) {
    display: flex;
    flex-flow: $direction;
    justify-content: space-between;
    align-items: center;
    flex-wrap: $wrap;
  }
  
  @mixin flex-even($direction: row, $wrap: nowrap) {
    display: flex;
    flex-flow: $direction;
    justify-content: space-around;
    align-items: center;
    flex-wrap: $wrap;
  }
  
  @mixin mq($width, $type: max) {
    @if map_has_key($breakpoints, $width) {
      $width: map_get($breakpoints, $width);
      // @if $type == max {
      //     $width: $width - 1px;
      // }
      @media only screen and (#{$type}-width: $width) {
        @content;
      }
    }
  }
  
  @mixin menu-style {
    position: absolute;
    width: 100%;
    box-shadow: $menu-shadow;
    //padding: 10px 0;
    z-index: 50;
    background: #fff;
    margin-top: 25px;
    border-radius: 6px;
    transform-origin: top;
    z-index: 6;
    float: left;
    li {
      padding: 10px 12px;
      list-style: none;
      .flag {
        margin: 0;
      }
    }
  }
  
  @mixin styledScrollbar {
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #D9E7FF;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(168, 159, 159);
      border-radius: 1rem;
    }
  }
  
  .footer {
    font-family: Raleway;
    color: #e2e2e2;
    background: linear-gradient( rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("./images/leafy.svg");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: -40%;
    padding: 20px 0px 80px;
    li {
      cursor: pointer;
    }
    @include mq("tablet",
    "min") {
      padding: 20px 0px 70px;
    }
    @include mq("desktop-med",
    "min") {
      padding: 60px 0px 60px;
    }
    .inner {
      margin: auto;
    }
    .links-sub {
      @include flex-btw(row,
      wrap);
      .link-section {
        min-width: 170px;
        &.last {
          align-self: flex-start;
        }
        &.form {
          position: relative;
          .error {
            color: $red;
            font-style: italic;
            font-size: 14px;
            position: absolute;
            left: 0;
          }
          form {
            display: flex;
            flex-wrap: nowrap;
            border: 2px solid $green;
            border-radius: 6px;
            overflow: hidden;
            input {
              padding: 14px;
              border: none;
              background-color: transparent;
              transition: background-color 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
            }
            input:focus {
              background-color: #fff;
            }
            button {
              background-color: $green;
              padding: 8px;
              color: #ffffff;
              font-weight: bold;
              &:disabled {
                opacity: 1;
                cursor: not-allowed;
              }
              @include mq("tablet",
              "min") {
                padding: 14px;
              }
            }
          }
        }
            ul {
          font-size: 16px;
          list-style-type: none;
          li {
            // display: inline-block;
            margin-bottom: 10px;
            color: #e2e2e2;
          }
        }
      }
    }
    .social-copyright {
      margin: 90px auto 10px;
      @include flex-btw(row,
      wrap);
      .links {
        @include flex-btw;
        width: 100%;
        @include mq("tablet",
        "min") {
          width: auto
        }
        a {
          display: inline-block;
          &:hover {
            color: #33d5ba;
          }
        }
      }
      p {
        width: 100%;
        text-align: center;
        padding-top: 12px;
        @include mq("tablet",
        "min") {
          width: auto;
          text-align: start;
          padding: 0;
        }
      }
    }
  }
  
  .iconList {
    $pointer: #d6f1ff;
    $arrow: #ddf8f4;
    $share: #fff0c2;
    $copy: #fff0c2;
    $notification: #fbd9c6;
    $alpha: #e8d1ff;
    h3 {
      font-family: Raleway;
      font-size: 25px;
      font-weight: bold;
      line-height: 1.3;
      text-align: left;
      color: #303030;
      margin-bottom: 41px;
      @media (max-width: 768px) {
        font-size: 20px;
      }
    }
    .fixer {
      display: none;
    }
    list-style: none;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 24px 0px;
      span {
        margin-left: 29px;
        font-family: Raleway;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 1.2px;
        color: #303030;
      }
    }
    .icon_box {
      flex-shrink: 0;
      border-radius: 14px;
      width: 54px;
      height: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 400px) {
        width: 34.8px;
        height: 34.8px;
        border-radius: 10px;
        svg {
          width: 15px;
          height: 18px;
        }
      }
    }
    .pointer {
      box-shadow: -9px 10px 30px $pointer;
      background: $pointer;
    }
    .arrow {
      box-shadow: -9px 10px 30px $arrow;
      background: $arrow;
    }
    .alpha {
      box-shadow: -9px 10px 30px $alpha;
      background: $alpha;
    }
    .copy {
      box-shadow: -9px 10px 10px $copy;
      background: $copy;
    }
    .notification {
      box-shadow: -9px 10px 30px $notification;
      background: $notification;
    }
    .share {
      box-shadow: -9px 10px 10px $share;
      background: $share;
    }
  }