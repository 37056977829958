@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,600;1,700&family=Raleway:wght@300;400;500;600;700;800;900&display=swap');
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import '~slick-carousel/slick/slick',
'~slick-carousel/slick/slick-theme';


.slick-prev {
  left: 5px;
  z-index: 9;
}
.slick-next {
  right: 5px;
  z-index: 9;
}
body {
  font-family: "Raleway", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  background-color: #f9f9f9;
  color: #202124;
}

nav {
  background: #fff;
  box-shadow: 0 2px 6px rgba(209, 214, 222, 30%);
}

.container {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.active-nav {
  color: #33d5ba;
  font-weight: 600;
  &::after {
    width: 100%;
    left: 0;
  }
}

li {
  line-height: 2.25rem;
  @media (max-width: 768px) { 
    line-height: 1.75rem;
   }
}

p {
  line-height: 2;
  margin: 1rem 0;
  @media (max-width: 768px) {
    line-height: 1.75;
    margin: .5rem 0;
  }
  // scale
}

.lighter-bg {
  background-color: #ffffffb8;
}

.py-16{
  @media (max-width: 768px) { 
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
   }
}

// .scroll {
//   height: 70px;
//   .logo {
//     height: 70px;
//   }
// }
// .logo {
//   background-color: #AEAC99;
//   // color: $primaryColor;
//   width: 350px !important;
//   height: 100px;
// }
// .nav-div {
//   height: 100px;
// }
// nav ul {
//   font-family: 'Open Sans', sans-serif;
//   li {
//     border-bottom: 2px solid ;
//     padding: 0 .25rem;
//   }
//   li:hover {
//     cursor: pointer;
//     // border-bottom-color: $primaryColor;
//   }
// }